import { useContext } from 'react'
import { IntlContext } from '#hh/client/lib/pages/withIntl'
import { defaultLocale, TranslationMessages, translateString } from '#hh/shared/intl'


export function useTranslations() {
	const intlContext = useContext(IntlContext)
	// console.debug('[hh/client] useTranslations', intlContext)
	return intlContext
}


// TODO these should sit on the useTranslation hook to get TranslationMessages injected.

// export function translateString(
// 	key: keyof TranslationMessages,
// 	messages: TranslationMessages,
// 	placeholders?: Record<string, string>,
// ) {
// 	const message = messages[key]

// 	if (!message) {
// 		console.warn(`missing translation for key: ${key}`)
// 		return key
// 	}
// 	if (placeholders) {
// 		// placeholder replacements may be either react elements or strings
// 		const parts = message.split(/{(.*?)}/)

// 		const interpolatedParts = parts.map((part, index) => {
// 			if (index % 2 === 0) {
// 				return part
// 			} else {
// 				const replacement = placeholders[part]

// 				return replacement
// 			}
// 		})
		
// 		return interpolatedParts.join('')
// 	}
// 	return messages[key]
// }

export { translateString } from '#hh/shared/intl'


export function translate(
	key: keyof TranslationMessages,
	messages: TranslationMessages,
	placeholders?: Record<string, React.ReactNode | string>,
) {
	const message = messages[key] as string

	if (!message) {
		console.warn(`missing translation for key: ${String(key)}`)
		return key
	}
	if (placeholders) {
		// placeholder replacements may be either react elements or strings
		const parts = message.split(/{(.*?)}/)
		let hasReactElements = false

		const interpolatedParts = parts.map((part, index) => {
			if (index % 2 === 0) {
				return part
			} else {
				const replacement = placeholders[part]
				if (typeof replacement !== 'string') {
					hasReactElements = true
				}
				return replacement
			}
		})
		
		if (hasReactElements) {
			return <>{interpolatedParts}</>
		}
		
		return interpolatedParts.join('')
	}
	return messages[key]
}
